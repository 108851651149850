h1 {
  font-size: 3.5rem;
  color: transparent;
  background-clip: text;
  width: 100%;

  @media (max-width: 450px) {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 1.5rem;
  display: inline-block;
  text-align: center;
  font-family: var(--);
}

a {
  text-decoration: none;
  color: var(--fg-color);
  font-size: 2rem;
  position: relative;
  display: block;

  &::after {
    content: '';
    position: absolute;
    transform: scale(0);
    top: 1.3em;
    left: -.5%;
    width: 101%;
    height: 3px;
    background-color: var(--fg-color);
    transition: all 0.3s ease-in-out;
  }

  &:hover::after {
    transform: scale(1);
  }
}
.home-container {
  display: flex;

  > * {
    flex: 0 0 50%;
  }
}

.home-image {
  width: 50vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 9999;

  @media (max-width: 500px) {
    background-position: 20%;
  }
}

.home-title {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
}

body {
  font-family: 'Koulen', cursive;
}

img {
  max-width: 100%;
  display: block;
}

.container {
  margin-inline: auto;
  margin-top: 5rem;
  position: relative;
  z-index: 0;
  background-color: var(--bg-color);
  padding-top: 1px;

  h1 {
      font-weight: normal;
      color: var(--fg-color);
      margin: 3rem auto;
      text-align: center;
      font-size: 3rem;
    }
}

.container-home {
  display: flex;
  flex-direction: column;
  margin-inline: auto;

  > * {
    display: block;
    margin-inline: auto;
  }

  > * + * {
    margin-top: 3rem;
  }

  .main-image {
    width: 500px;
  }

  .logo {
    max-width: 300px;
  }
}

.container-works {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  gap: 1.5rem;
  max-width: 1000px;
  margin-inline: auto;
  min-height: 100vh;

  .card {
    max-width: 300px;
    margin-inline: auto;
  }
}

.container-book {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 0 1em;

}

.video-container {
  max-width: 100%;
  flex-grow: 1;


  .youtube-video {
    aspect-ratio: 16 / 9;
    width: 800px;
    max-width: 100%;
  }
}
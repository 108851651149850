.header {
  position: fixed;
  height: 5rem;
  top: 0;
  left: 0;
  right: 0;
  font-family: 'Koulen', cursive;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  backdrop-filter: blur(5px);
  max-height: 10vh;
  background-color: #fff;
  z-index: 1000;

  .menu-items {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    height: 100vh;
    z-index: 1;
    transform: translateX(120vh);
    background: var(--bg-color);
    transition: transform 0.3s ease-in-out;

    &.open {
      transform: translate(0);
    }

    @media (min-width: 1050px) {
      flex-direction: row;
      position: unset;
      transform: translateX(0);
      height: unset;
      background: transparent;
    }

    li {
      a {
        font-size: 1.3rem;
        padding: 0.5em 1em;

        &::after {
          top: 2em;
        }
      }
    }
  }

    .logo {
      font-size: 1rem;
      font-weight: 900;

      @media (min-width: 600px) {
        font-size: 2rem;
      }
    }
}

// nav menu button
.menu-btn {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 2;
  margin-top: 1.5rem;
  margin-left: auto;

  @media (min-width: 1050px) {
    display: none;
  }
}

.menu-btn__lines,
.menu-btn__lines::before,
.menu-btn__lines::after {
  width: 1.5rem;
  height: 0.2rem;
  background-color: var(--fg-color);
  transition: all 0.5s ease-in-out;
}

.menu-btn__lines::before,
.menu-btn__lines::after {
  content: '';
  position: absolute;
}

.menu-btn__lines::before {
  transform: translateY(-0.5rem);
}

.menu-btn__lines::after {
  transform: translateY(0.5rem);
}

// animation
.menu-btn.open .menu-btn__lines {
  transform: translateX(2rem);
  background-color: transparent;
}

.menu-btn.open .menu-btn__lines::before {
  transform: rotate(45deg) translate(-1.5rem, 1.5rem);
  background-color: var(--fg-color);
}

.menu-btn.open .menu-btn__lines::after {
  transform: rotate(-45deg) translate(-1.5rem, -1.5rem);
  background-color: var(--fg-color);
}

.menu-btn.open .main-navigation {
  transform: translate(0);
}

